import React from 'react';
import { LoginPage } from '~/categories/admin/components/pages/LoginPage';
import { useAdminPageContext } from '~/categories/admin/hooks/commons/useAdminPageContext';
import { getSingleActionLayout } from '~/components/pages/other/getSingleActionLayout';
import { NextPageWithLayout } from '~/types/layout';

const Page: NextPageWithLayout = () => {
  const pageContext = useAdminPageContext();
  return <LoginPage {...pageContext} />;
};

Page.Layout = getSingleActionLayout();

Page.config = {
  pageTitle: 'ログイン',
};

export default Page;
