import React from 'react';
import { GoogleAuthLogin } from '~/categories/admin/components/domains/login/GoogleAuthLogin';
import { useLoginBySSO } from '~/categories/admin/hooks/auth/useLoginBySSO';
import { AdminPageContext } from '~/categories/admin/hooks/commons/useAdminPageContext';

export type Props = AdminPageContext;

export const LoginPage: React.FC<Props> = (props) => {
  const hook = useLoginBySSO(props.router);

  return (
    <GoogleAuthLogin
      onClickLogin={() => {
        hook.actions.loginBySSO();
      }}
    />
  );
};
