import React from 'react';
import GoogleLogo from '~/assets/icon/google.svg';
import TimeeIcon from '~/assets/logo/with-icon.svg';
import * as styles from './index.styles';

export type Props = {
  onClickLogin: () => void;
};

export const MESSAGE = {
  DESCRIPTION: 'Admin用ログインページ',
} as const;

export const GoogleAuthLogin: React.FC<Props> = (props: Props) => {
  return (
    <div css={styles.container}>
      <img css={styles.logoImage} src={TimeeIcon} alt={''} />

      <div css={styles.description}>{MESSAGE.DESCRIPTION}</div>

      <button css={styles.loginButton.container} onClick={props.onClickLogin}>
        <img alt="" src={GoogleLogo} css={styles.loginButton.logo} />
        <div css={styles.loginButton.description}>Googleでログイン</div>
      </button>
    </div>
  );
};
